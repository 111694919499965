import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Seo from '../components/seo';
import { ContentWrapper } from '../components/common';
import { Title1 } from '../components/headings';
import { HOME } from '../routes';
import { Link } from 'gatsby';
import logoSrc from '../images/logo-rezidence-u-anicky.png';
import theme from '../theme';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary_grey};

  img {
    width: 100%;
    height: auto;
    max-width: 280px;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    img {
      max-width: 350px;
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: ${({theme}) => theme.colors.white};
  background-color: transparent;
  border: 0;
  width: 100%;
  padding: 0.575rem 2.25rem;
  transition: all .3s ease;
  border: 1px solid ${({theme}) => theme.colors.white};

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.secondary_grey};
    background-color:${({theme}) => theme.colors.white};
  }
`;

const Container = styled.div`
  padding: 7rem 0;
  text-align: center;

  ${Title1} {
    font-size: 5rem;
    line-height: 6.25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    color: ${({theme}) => theme.colors.white};
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    line-height: 3.75rem;
    margin-bottom: 2.5rem;
    color: ${({theme}) => theme.colors.white};
  }
`;

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Wrapper>
      <Seo title="Stránka nebyla nalezena" />
      <Container>
        <ContentWrapper>
          <img src={logoSrc} alt="Logo - Rezidence u Aničky" />
          <Title1>404</Title1>
          <p>Stránka nebyla nalezena</p>
          <StyledLink to={HOME}>Přejít na hlavní stránku</StyledLink>
        </ContentWrapper>
      </Container>
    </Wrapper>
  </ThemeProvider>
)

export default NotFoundPage;
